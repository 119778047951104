@import 'components/ui/styles/colors.module';
@import 'components/ui/styles/_scrollbar';

.container {
  width: 100%;
}

.containerFloating {
  position: relative;
}

.label {
  // Typography paragraph small bold
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 8px;
  display: inline-block;
}

.wrapper {
  display: flex;
  align-items: center;
  position: relative;

  background-color: $white;
  border: 1px solid $gray-scale-50;
  border-radius: 12px;

  padding: 8px 0;
  width: 100%;

  .leftIcon,
  .rightIcon {
    position: absolute;
    cursor: pointer;
  }

  .leftIcon {
    left: 10px;
  }

  .rightIcon {
    right: 10px;
  }
}

.leftIcon {
  display: flex;
}

.rightIcon {
  display: flex;
}

.input {
  // Typography paragraph small
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px 32px 8px 20px;

  &.hasLeftIcon {
    padding-left: 40px;
  }
}

.light {
  &.label {
    color: $black;
  }

  @include custom-dark-scrollbar;
}

.dark {
  &.label {
    color: $white;
  }
  &.wrapper {
    background-color: black;
    border: 1px solid $gray-scale-50;
    border-radius: 12px;
  }

  &.input {
    color: $white;
  }

  &.options {
    background-color: $black;

    .option {
      color: $white;

      &:hover {
        color: $black;
      }
    }
  }

  @include custom-light-scrollbar;
}

.options {
  width: 100%;
  height: auto;
  max-height: 433px;
  margin-top: 5px;
  background-color: $white;
  border: 1px solid $gray-scale-50;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  .option {
    text-align: left;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 0;
    color: $black;
    border-bottom: 1px solid $gray-scale-50;
    border-right: 1px solid $gray-scale-50;
    box-sizing: border-box;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $gray-scale-50;
    }

    span {
      padding: 14px 20px 14px 10px;

      &.hasLeftIcon {
        padding-left: 40px;
      }
    }
  }

  .selectedOption {
    background: #f0f6ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 0;
  }
}

.optionsFloating {
  position: absolute;
  top: 100%;
  z-index: 10;
}
