@import 'components/ui/styles/tools-colors';
@import 'components/ui/styles/breakpoints-deprecated';
@import 'components/ui/styles/colors-deprecated.module';

.release-notes {
  &__header {
    background-color: $seashell;
    display: flex;
    width: 100%;
    padding: 48px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $blackLight;

    /* Headline/H-100 */
    font-family: 'Fabriga';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-direction: column;
    &__git-info {
      width: 560px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h4 {
        color: var(--foundation-gray-scale-gray-scale-400, $charcoal);
        /* Headline/H-400B */
        font-family: 'Fabriga';
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 117.647% */
        width: 560px;
      }

      h3 {
        color: var(--foundation-gray-scale-gray-scale-700, $blackLight);
        font-family: 'Inter', serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        width: 560px;
      }
      h2 {
        display: flex;
        margin-top: 0;
        color: var(--foundation-gray-scale-gray-scale-700, $blackLight);
        font-family: 'Fabriga';
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 560px;
      }
      ul {
        width: 550px;
        padding-left: 10px;
        overflow-wrap: break-word;
      }
    }
  }
  &__content-container {
    background-color: $white;
    width: 100%;
    justify-content: center;
    display: flex;
  }
  &__link-and-notes {
    display: flex;
    justify-content: space-between;
    gap: 112px;
    margin: 63px 437px 0 437px;
    width: 855px;
  }
  &__platform-links {
    color: var(--foundation-gray-scale-gray-scale-400, $charcoal);

    /* Headline/H-400R */
    font-family: 'Fabriga';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 117.647% */
    width: 180px !important;
    .selected {
      font-family: 'Fabriga Bold', serif;
      color: var(--foundation-gray-scale-gray-scale-700, $blackLight);
    }
    h4 {
      color: var(--foundation-gray-scale-gray-scale-300, #686868);

      /* Headline/H-400B */
      font-family: 'Fabriga';
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 117.647% */
      font-family: 'Fabriga Bold', serif;
      text-decoration: underline;
    }
    div {
      cursor: pointer;
      padding: 4px 0;
      gap: 10px;
      &:hover {
        color: $blue;
      }
    }
  }
  &__release-notes {
    justify-content: center;
    h2 {
      justify-content: left;
    }
  }
}
