.page-not-found {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .main-header__logo {
    margin-left: 0;
    height: 40px;
    left: 0;
    top: 0;
    position: relative;
  }
}
