@import './fontawesome.css';

$fa-font-path: '../../../static/fonts/fontawesome';

@font-face {
  font-family: 'Font Awesome 5 Regular';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fa-font-path}/fa-regular-400.eot');
  src:
    url('#{$fa-font-path}/fa-regular-400.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Regular';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Solid';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src:
    local('Font Awesome 5 Solid'),
    url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Solid';
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('#{$fa-font-path}/fa-brands-400.eot');
  src:
    url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
