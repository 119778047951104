@import 'components/ui/styles/colors-deprecated.module';

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner-inner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid $silver;
  border-top-color: $blue;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
