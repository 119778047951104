@import 'components/ui/styles/breakpoints.module';
@import 'components/ui/styles/colors.module';
@import 'components/ui/styles/variables';

.buttonContainer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $links-300;
  color: $white;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.log {
  position: fixed;
  bottom: 80px;
  left: 20px;
  background: $white;
  border: 1px solid $gray-scale-100;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba($black, 0.2);
  border-radius: 10px;
}

.logItem {
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}

.logItem::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: $links-300;
}

.separator {
  border: none;
  border-top: 1px solid $gray-scale-100;
  margin: 10px 0;
}
