@font-face {
  font-family: 'Fabriga';
  font-style: normal;
  font-weight: 400;
  src: url('../../static/fonts/fabriga/Fabriga-Regular.ttf');
}

@font-face {
  font-family: 'Fabriga';
  font-style: italic;
  font-weight: 400;
  src: url('../../static/fonts/fabriga/Fabriga-Italic.ttf');
}

@font-face {
  font-family: 'Fabriga';
  font-style: normal;
  font-weight: 700;
  src: url('../../static/fonts/fabriga/Fabriga-Bold.ttf');
}

@font-face {
  font-family: 'Fabriga';
  font-style: italic;
  font-weight: 700;
  src: url('../../static/fonts/fabriga/Fabriga-Bold-Italic.ttf');
}

// the ones below are deprecated, leaving them for now since they are still widely used
@font-face {
  font-family: 'Fabriga Italic';
  src: url('../../static/fonts/fabriga/Fabriga-Italic.ttf');
}

@font-face {
  font-family: 'Fabriga Bold';
  src: url('../../static/fonts/fabriga/Fabriga-Bold.ttf');
}

@font-face {
  font-family: 'Fabriga Bold Italic';
  src: url('../../static/fonts/fabriga/Fabriga-Bold-Italic.ttf');
}
