@font-face {
  font-family: 'Montserrat';
  src: url('../../static/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../../static/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../../static/fonts/montserrat/Montserrat-Bold.ttf');
}
