* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

main {
  height: 100%;
  overflow-y: auto;

  &.main-container {
    display: flex;
    flex-direction: column;
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  height: 100%;
}

#app {
  height: 100%;

  &.non-scrollable {
    overflow: inherit;
  }
}

input {
  outline: 0;
}

// fixed dimensions used by multiple components

$sidebar-width: 393px;
$sidebar-tabs-width: 73px;
$sharemap-header-height: 72px;
:export {
  sidebar-width: $sidebar-width;
  sharemap-header-height: $sharemap-header-height;
  sidebar-tabs-width: $sidebar-tabs-width;
}
