@import 'components/ui/styles/colors.module';
@import 'components/ui/styles/variables';

.container {
  font-family: 'Inter', serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0;
}

.tile {
  display: grid;
  grid-template-columns: 5fr 2fr 3fr 1fr;
  gap: 30px;
  padding: 5px 2px 5px 10px;
  border-bottom: 1px solid $gray-scale-50;
  align-items: center;
}

.overrideSelector {
  background-color: $gray-scale-700;
  color: $white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}

.editButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.modal {
  max-width: unset;
  max-height: 95%;
  padding: 10px;
}

.payloadItem {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
