@import 'components/ui/styles/tools-colors';
@import 'components/ui/styles/breakpoints-deprecated';
@import 'components/ui/styles/colors-deprecated.module';
@import 'components/ui/styles/colors.module';

.main-header {
  color: $white;
  background: $white;
  border-bottom: 1px solid $grayLightest;

  a {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
  }

  &__logo {
    position: absolute;
    left: 33px;
    top: 15px;
  }

  &__subdomain-logo {
    height: 40px;
    margin-left: 20px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    line-height: 70px;

    &__burger {
      box-sizing: border-box;
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      gap: 10px;
      margin: 0;
      width: 48px;

      border: none;
      border-radius: 5px;

      &__icon {
        background: url('/static/images/navbar/burger_new.svg') no-repeat center;
        width: 24px;
        height: 24px;
      }

      &__close_icon {
        background: url('/static/images/navbar/close_new.svg') no-repeat center;
        width: 24px;
        height: 24px;
      }

      .fa-bars:before,
      .fa-times:before {
        font-size: 24px;
        color: $white;
      }
    }
  }

  &__burger {
    position: fixed;
    width: 390px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    background: $white;

    &__full_height {
      height: 100%;
    }

    &__nav {
      &__left,
      &__right {
        margin-right: 0;
        color: $white;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 10px 5px 5px;
        gap: 15px;

        &__account-box {
          padding-right: 10px;
          padding-left: 5px;
          margin-left: auto;
          margin-right: auto;
          border: 1px solid $grayLightest;
          gap: 8px;
          border-radius: 10px;
          width: 350px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: left;
          background-color: white;

          &__choose-account-modal {
            background-color: rgba(107, 166, 255, 0.1);
            border: 1px rgba(107, 166, 255, 0.1);
          }
        }

        &__profile {
          display: flex;
          justify-content: flex-start;
          font-family: 'Inter', serif;
          font-style: normal;
          font-weight: 600;
          height: 30px;
          width: auto;
          cursor: pointer;
          color: $blackLight;
          &__chevron {
            position: absolute;
            right: 30px;
            padding-top: 5px;
          }
          &__profile-icon {
            padding-left: 0;
          }
        }

        p {
          margin: 0;
          padding-top: 3px;
          padding-left: 7px;
        }

        .buy-now-button {
          width: 350px;
        }
      }

      &__left {
        width: 378px;
        padding-bottom: 20px;
        border-top: 1px solid $grayLightest;
        display: block;
        justify-content: left;
        padding-top: 0;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        &__active-tab {
          fill: $blue !important;
          color: $blue !important;
          text-decoration: none !important;
          a,
          div {
            text-decoration: none !important;
            color: $blue !important;
          }
          a:focus,
          div:focus {
            text-decoration: none !important;
          }
        }

        span {
          padding-top: 7px;
        }

        li {
          padding-left: 8px;
          padding-top: 12px;
        }

        a,
        div {
          height: 30px;
          font-size: 16px;
          display: flex;
          flex-direction: row;
          padding: 0 8px;
          align-items: center;
          color: #686868;

          i {
            background: url('/static/images/navbar/arrow_angle_right.svg')
              no-repeat center;
            width: 24px;
            height: 24px;
          }
        }

        .pro-tag {
          display: inline-flex;
          align-items: center;
          padding: 0px 6px;
          color: $white;
          background-color: $red;
          height: 16px;
          border-radius: 8px;
          font-size: 11px;
          line-height: 16px;
          margin-left: 4px;
        }
      }
    }
  }

  &__nav {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;

    li {
      display: flex;
      margin: 0 12px;
      border-radius: 2px;
    }

    &__left {
      width: 500px;
      display: flex;
      justify-content: center;
      height: 100%;

      &__active-tab {
        fill: $blue !important;
        color: $blue !important;
        border-bottom-color: $blue !important;
        a,
        div {
          color: $blue !important;
        }
        &:focus {
          text-decoration: none !important;
        }
      }

      li:hover {
        a,
        div {
          border-bottom-color: $blue;
          color: $blue;
          fill: $blue;
          text-decoration: none;
        }
      }

      li {
        margin: 0 15px;
        display: inline;
        height: 100%;

        a {
          padding: 0 12px;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          border-bottom: 3px solid transparent;

          .pro-tag {
            display: inline-flex;
            align-items: center;
            padding: 0px 6px;
            color: $white;
            background-color: $red;
            height: 16px;
            line-height: 16px;
            border-radius: 8px;
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      a,
      div {
        font-family: 'Inter', serif;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        color: $independence;
        vertical-align: top;
      }

      svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__right {
      gap: 20px;
      display: flex;
      align-items: center;
      font-size: 13px;
      position: absolute;
      right: 30px;
      &__account-box {
        margin-top: 5px;
        border: 1px solid $grayLightest;
        gap: 8px;
        border-radius: 10px;
        width: 122px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;

        &__choose-account-modal {
          background-color: rgba(107, 166, 255, 0.1);
          border: 1px rgba(107, 166, 255, 0.1);
        }
      }

      &__profile {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 600;
        height: 30px;
        width: auto;
        cursor: pointer;
        color: $blackLight;
        align-items: center;
        &__profile-icon {
          padding-left: 16.5px;
          padding-top: 20px;
        }
        &__chevron {
          padding-top: 4px;
          padding-right: 18px;
        }
      }

      .reskin-button {
        font-size: 13px;

        &__icon {
          margin-top: -3px;
          width: 12px;
          height: 12px;
        }
      }

      li {
        display: flex;
        column-gap: 10px;
        align-items: center;

        a {
          color: $havelockBlue;
          font-size: 12px;
        }

        p {
          height: auto;
          width: auto;
          display: flex;
          margin: 0 -3px 0;

          align-items: center;
        }

        &__chevron {
          svg {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }

  .active-tab {
    fill: $blue;
    color: $blue;

    a {
      color: $blue;
    }
  }

  .vertical-line {
    margin-top: 18px;
    margin-bottom: 18px;
    border-left: solid 1px $blue;
    width: 1px;
  }

  .log-out-error-msg {
    position: absolute;
    background-color: $redLight;
    padding: 6px;
    border-radius: 4px;
    left: calc(50% - 119px);
    top: 50px;
    color: $grayLight;
    animation-name: error-message;
    animation-duration: 1s;
  }

  @include phablet() {
    &__logo {
      display: flex;
    }

    &__container {
      padding: 16px 20px;

      &__burger {
        display: none;
      }
    }

    &__burger {
      display: none;
    }

    &__nav {
      display: flex;
    }

    .vertical-line {
      display: none;
    }
  }

  @include tablet() {
    &__logo {
      display: flex;
    }

    &__container {
      padding: 16px 20px;

      &__burger {
        display: none;
      }
    }

    &__burger {
      display: none;
    }

    &__nav {
      display: flex;
    }

    .vertical-line {
      display: none;
    }
  }

  @media screen and (max-width: 1210px) {
    .create-new-map-button {
      display: none !important;
    }
  }

  @include phablet() {
    &__logo {
      display: flex;
    }

    &__container {
      justify-content: flex-end; /* Add this line to align items to the far right of the container */
      padding: 16px 20px;

      &__burger {
        display: flex;
      }
    }

    &__burger {
      display: block;
      z-index: 2001;
    }

    &__nav {
      display: none;
    }

    .vertical-line {
      display: none;
    }
  }
}

@keyframes error-message {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Old Nav Bar css

.oldmain-header {
  color: $white;
  background: $blackLight;

  a {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
  }

  a:hover {
    text-decoration: underline;
  }

  &__logo {
    height: 40px;
    margin-left: 60px;
  }

  &__subdomain-logo {
    height: 40px;
    margin-left: 20px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    line-height: 70px;

    &__burger {
      box-sizing: border-box;
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      gap: 10px;
      margin: 0;
      width: 48px;

      border: none;
      border-radius: 5px;

      &__icon {
        background: url('/static/images/navbar/burger.svg') no-repeat center;
        width: 24px;
        height: 24px;
      }

      &__close_icon {
        background: url('/static/images/navbar/close.svg') no-repeat center;
        width: 24px;
        height: 24px;
      }

      .fa-bars:before,
      .fa-times:before {
        font-size: 24px;
        color: $white;
      }
    }
  }

  &__burger {
    position: fixed;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    background: $white;

    &__full_height {
      height: 100%;
    }

    &__nav {
      &__left,
      &__right {
        color: $white;
        padding: 14px 16px;
        font-size: 17px;
        display: block;

        li {
          list-style-type: none;
        }

        a {
          padding: 14px 16px;
          display: block;

          font-size: 17px;
          line-height: 26px;
        }
      }

      &__left {
        padding-top: 34px;

        li {
          padding: 12px 20px 14px;
        }

        a {
          /* Layout */
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          padding: 14px 16px;

          /* Style */
          color: $blackLight;

          i {
            background: url('/static/images/navbar/arrow_angle_right.svg')
              no-repeat center;
            width: 24px;
            height: 24px;
          }
        }
      }

      &__right {
        padding-top: 36px;

        li {
          padding-top: 20px;
        }

        a {
          display: flex;
          align-items: flex-end;
          color: $havelockBlue;
        }
      }
    }
  }

  &__nav {
    list-style: none;
    margin: 0;
    flex-direction: row;
    display: flex;

    li {
      display: inline;
      margin: 0 12px;
      border-radius: 2px;
    }

    &__left {
      margin-right: 40px;

      a {
        font-size: 17px;
        line-height: 26px;
        color: $white;
      }
    }

    &__right {
      display: flex;
      margin-right: 60px;

      .profile {
        cursor: pointer;
      }

      li {
        display: flex;
        column-gap: 10px;

        a {
          color: $havelockBlue;
          font-size: 12px;
        }

        span {
          display: flex;
          align-items: center;
        }

        .chevron {
          svg {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }

  .vertical-line {
    margin-top: 18px;
    margin-bottom: 18px;
    border-left: solid 1px $independence;
    width: 1px;
  }

  .log-out-error-msg {
    position: absolute;
    background-color: $redLight;
    padding: 6px;
    border-radius: 4px;
    left: calc(50% - 119px);
    top: 50px;
    color: $grayLight;
    animation-name: error-message;
    animation-duration: 1s;
  }
}

@include phablet() {
  .oldmain-header {
    &__logo {
      padding: 4px;
      width: 161px;
      height: 48.76px;
    }

    &__container {
      padding: 16px 20px;

      &__burger {
        display: flex;
      }
    }

    &__burger {
      display: block;
    }

    &__nav {
      display: none;
    }

    .vertical-line {
      display: none;
    }

    .main-header__burger__nav__right {
      span {
        padding-left: 16px;
      }
    }
  }
}

@include tablet() {
  .oldmain-header {
    &__logo {
      padding: 4px;
      width: 161px;
      height: 48.76px;
    }

    &__container {
      padding: 16px 20px;

      &__burger {
        display: flex;
      }
    }

    &__burger {
      display: block;
    }

    &__nav {
      display: none;
    }

    .vertical-line {
      display: none;
    }
  }
}

@include phone() {
  .oldmain-header {
    &__logo {
      padding: 4px;
      width: 161px;
      height: 48.76px;
    }

    &__container {
      padding: 16px 20px;

      &__burger {
        display: flex;
      }
    }

    &__burger {
      display: block;
    }

    &__nav {
      display: none;
    }

    .vertical-line {
      display: none;
    }
  }
}
