@use 'sass:map';
@use 'sass:string';
@import 'components/ui/styles/colors.module';
@import 'components/ui/styles/breakpoints.module';

// Common styles
.base {
  font-style: normal;
  color: $gray-scale-700;
  margin: 0;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

$types: (
  Display: (
    defaults: (
      font-family: string.unquote('Fabriga, serif'),
      line-height: normal,
      font-weight: 700,
    ),
    variants: (
      Regular: (
        font-size: 50px,
      ),
      Large: (
        font-size: 60px,
      ),
    ),
  ),
  Header: (
    defaults: (
      font-family: string.unquote('Fabriga, serif'),
      line-height: normal,
    ),
    variants: (
      1: (
        font-size: 35px,
        font-weight: 700,
      ),
      2: (
        font-size: 24px,
        font-weight: 700,
      ),
      3r: (
        font-size: 20px,
        font-weight: 400,
      ),
      3b: (
        font-size: 20px,
        font-weight: 700,
      ),
      3bi: (
        font-size: 20px,
        font-weight: 700,
        font-style: italic,
      ),
      4r: (
        font-size: 17px,
        font-weight: 400,
        line-height: 20px,
      ),
      4b: (
        font-size: 17px,
        font-weight: 700,
        line-height: 20px,
      ),
      5r: (
        font-size: 14px,
        font-weight: 400,
      ),
      5b: (
        font-size: 14px,
        font-weight: 700,
      ),
    ),
  ),
  Paragraph: (
    defaults: (
      font-family: string.unquote('Inter, serif'),
      line-height: 20px,
    ),
    variants: (
      Large: (
        font-size: 17px,
        font-weight: 400,
        line-height: 26px,
      ),
      Regular: (
        font-size: 14px,
        font-weight: 400,
      ),
      SemiBold: (
        font-size: 14px,
        font-weight: 600,
      ),
      Bold: (
        font-size: 14px,
        font-weight: 700,
      ),
      Small: (
        font-size: 12px,
        font-weight: 400,
        line-height: normal,
      ),
      SmallBold: (
        font-size: 12px,
        font-weight: 700,
        line-height: normal,
      ),
      SmallBoldItalic: (
        font-size: 14.52px,
        font-weight: 700,
        line-height: normal,
        font-style: italic,
      ),
    ),
  ),
  Link: (
    defaults: (
      color: $links-300,
      display: block,
      word-break: break-word,
      font-family: string.unquote('Inter, serif'),
      line-height: normal,
    ),
    variants: (
      Normal: (
        font-size: 14px,
        font-weight: 400,
      ),
      Primary: (
        font-size: 14px,
        font-weight: 500,
        text-decoration: underline,
        line-height: 20px,
      ),
      SmallBold: (
        font-size: 12px,
        font-weight: 700,
      ),
      SmallRegular: (
        font-size: 12px,
        font-weight: 400,
        text-decoration: underline,
      ),
    ),
  ),
  Label: (
    defaults: (
      font-family: unquote('Inter, serif'),
      line-height: normal,
      font-weight: 400,
    ),
    variants: (
      Regular: (
        font-size: 14px,
      ),
      Bold: (
        font-size: 14px,
        font-weight: 700,
      ),
      Optional: (
        font-size: 14px,
        font-style: italic,
      ),
      OptionalBold: (
        font-size: 12px,
        font-style: italic,
        font-weight: 700,
      ),
      Placeholder: (
        font-size: 14px,
        color: $gray-scale-200,
      ),
      Description: (
        font-size: 12px,
      ),
    ),
  ),
  Button: (
    defaults: (
      font-family: unquote('Inter, serif'),
      line-height: normal,
      font-weight: 600,
    ),
    variants: (
      1: (
        font-size: 16px,
      ),
      2: (
        font-size: 13px,
      ),
      3: (
        font-size: 11px,
      ),
    ),
  ),
  Metadata: (
    defaults: (
      font-family: unquote('Inter, serif'),
      line-height: normal,
      font-size: 10px,
    ),
    variants: (
      Regular: (
        font-weight: 400,
      ),
      Bold: (
        font-weight: 700,
      ),
    ),
  ),
);

// this creates all the regular classes and responsive classes based on the configuration above
@each $type, $type-config in $types {
  $defaults: map.get($type-config, 'defaults');
  $variants: map.get($type-config, 'variants');

  // base classes
  @each $variant, $variant-properties in $variants {
    $properties: map-merge($defaults, $variant-properties);

    .#{$type}#{$variant} {
      @each $property, $value in $properties {
        #{$property}: $value;
      }
    }
  }

  // responsive classes
  @each $device in ('desktop', 'laptop', 'tablet', 'phone') {
    @include breakpoint($device) {
      @each $variant, $variant-properties in $variants {
        $properties: map.merge($defaults, $variant-properties);

        .#{$device}#{$type}#{$variant} {
          @each $property, $value in $properties {
            #{$property}: $value;
          }
        }
      }
    }
  }
}
