.bottom {
  rotate: 180deg;
}
.left {
  rotate: 270deg;
}
.top {
  rotate: 0deg;
}
.right {
  rotate: 90deg;
}
