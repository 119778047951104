.error-boundary {
  padding: 20px;

  * {
    text-align: left;
  }

  p {
    &:not(:first-child) {
      padding-left: 20px;
    }
  }

  h2 {
    &:first-child {
      margin-top: unset;
    }
  }

  h3 {
    font-weight: bold;
    margin: 0;
  }
}
