@import 'components/ui/styles/colors.module';

@mixin custom-scrollbar($color) {
  ::-webkit-scrollbar {
    scroll-padding-bottom: 0;
    width: 6px;
    background-color: rgba($color, 0);
    border-radius: 100px;
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba($color, 0.09);
  }

  ::-webkit-scrollbar:horizontal {
    display: none;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: rgba($color, 0.5);
    border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba($color, 0);
    min-height: 10px; /* Prevent it from getting too small */
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba($color, 0.61);
    border-radius: 100px;
  }
}

@mixin custom-dark-scrollbar {
  @include custom-scrollbar($black);
}

@mixin custom-light-scrollbar {
  @include custom-scrollbar($white);
}
