@import 'components/ui/styles/colors-deprecated.module';

.reskin-checkbox {
  outline: 0;
  margin-right: 10px;
  vertical-align: -1px;
  width: 20px;
  height: 20px;
  border: 1.5px solid $grayLight;
  border-radius: 3px;
  background: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    visibility: hidden;
  }

  &--checked {
    border-color: $blue;
    span {
      display: block;
      visibility: visible;
      width: 12px;
      height: 12px;
      background: $blue;
      border-radius: 1px;
    }
  }

  &--looks-disabled {
    background: $grayLight;
    cursor: default;
  }
}

.reskin-checkbox:disabled {
  background: $grayLight;
}
