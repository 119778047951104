@import 'components/ui/styles/colors-deprecated.module';

.reskin-button {
  padding: 14.5px 20px;
  height: 48px;
  background: $blue;
  color: $white;
  border-radius: 12px;
  border: none;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--white {
    background: $white;
  }

  &--blue {
    background: $blue;
  }

  &--gray {
    background: $grayLight;
    color: $blackLight;
  }

  &--grayLightest {
    background: $grayLightest;
  }

  &--black {
    background: $blackLight;
  }

  &--seashell {
    background: $seashell;
  }

  &--red {
    background: $red;
  }

  &--redLight {
    background: $redLight;
  }

  &--fWhite {
    color: $white;
  }

  &--fBlack {
    color: $blackLight;
  }

  &--fRedDark {
    color: $mandy;
  }

  &--w100 {
    width: 100px;
  }

  &--w150 {
    width: 150px;
  }

  &--w200 {
    width: 200px;
  }

  &--w250 {
    width: 250px;
  }

  &--w270 {
    width: 270px;
  }

  &--w280 {
    width: 280px;
  }

  &--w500 {
    width: 500px;
  }

  &--w50p {
    width: 50%;
  }

  &--w100p {
    width: 100%;
  }

  &--wAuto {
    width: auto;
  }

  &--small {
    padding: 8px 16px;
    height: auto;
    border-radius: 6px;
    font-size: 13px;
  }

  &--scale__small {
    font-size: 13px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    height: 32px;
    padding: 7px 16px;
    width: auto;
  }

  &__button-icon {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-left: 5.3px;
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__light-blue:disabled {
    background: $havelockBlue;
  }

  &__secondary {
    background: $grayLightest;
    color: $blackLight;
  }
}
