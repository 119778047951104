@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/gotham/Gotham.otf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/gotham/GothamBold.otf');
}

@font-face {
  font-family: 'Gotham Italic';
  src: url('../../static/fonts/gotham/GothamItalic.otf');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../../static/fonts/gotham/GothamMedium.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../../static/fonts/gotham/GothamBook.otf');
}
