.error-screen {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  padding-top: 25px;

  .main-header__logo {
    margin-left: 0;
    height: 40px;
    left: 0;
    top: 0;
    position: relative;
  }
}
