$mobile-breakpoint: 449px; // mobile: from 0 to 448
$tablet-breakpoint: 769px; // tablet: from 449 to 768
$laptop-breakpoint: 1025px; // laptop: from 769 to 1024
$desktop-breakpoint: 1440px; // desktop: from 1025 to 1440

:export {
  mobile: $mobile-breakpoint;
  tablet: $tablet-breakpoint;
  laptop: $laptop-breakpoint;
  desktop: $desktop-breakpoint;
}

@mixin phone {
  @media screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $desktop-breakpoint) {
    @content;
  }
}

// this mixin is meant to be used dinamically by the design system components
// since it's not possible to use variables in the @include directive unless
// it's a parameterized mixin.
// for example: `@include $device-mixin-name` is not possible in scss.
// but `@include breakpoint($device-name)` is
@mixin breakpoint($type) {
  @if $type == 'phone' {
    @include phone {
      @content;
    }
  } @else if $type == 'tablet' {
    @include tablet {
      @content;
    }
  } @else if $type == 'laptop' {
    @include laptop {
      @content;
    }
  } @else if $type == 'desktop' {
    @include desktop {
      @content;
    }
  }
}
