@import 'components/ui/styles/tools-colors';
@import 'components/ui/styles/breakpoints-deprecated';

.custom-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.5);

  &--merge-parcels {
    overflow: hidden;
    width: 400px;
    height: 100px;
    top: 5px;
    left: 35%;
    background-color: transparent;
  }

  &--save-as {
    overflow: hidden;
    z-index: 3500;
  }

  &--invisible {
    position: relative;
  }

  &--transparent {
    background: transparent;
  }

  &--profile-selector {
    background: transparent;
    pointer-events: none;
  }

  @include media-max(phone, tablet, phablet) {
    &--profile-selector {
      background: rgba(black, 0.5);
      pointer-events: all;
    }
  }
}

.custom-modal {
  position: absolute;
  background: white;
  overflow: auto;
  border-radius: 12px;
  outline: none;
  max-height: 100vh;
  max-width: 600px;
  width: -webkit-fill-available;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  pointer-events: all;

  h4 {
    margin: 0;
    padding: 15px 20px;
    background: $seashell;
  }

  main {
    padding: 20px;
  }

  &__title {
    position: relative;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid $seashell;

    .close-icon {
      position: absolute;
      right: 0;
      cursor: pointer;
      height: 12px;
      width: 12px;

      > svg {
        height: 12px;
        width: 12px;
      }
    }

    &__without-border-bottom {
      border-bottom: none;
    }

    p {
      margin: 0;
      color: $osloGray;
      font-family: 'OpenSans-Regular';

      span {
        color: $outerSpace;
        font-family: 'OpenSans-SemiBold';
        font-size: 14px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $grayLight;
    padding: 15px 20px;
  }

  .leftAlign {
    justify-content: flex-start;

    button:nth-child(1) {
      margin-left: 0;
    }
  }

  &--top {
    left: 50%;
    transform: translate(-50%, 0) !important;
  }

  &--full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  &--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }

  &--center-full {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
  }

  &--center-all {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
  }

  &--scrollable {
    position: relative;
    top: 45%;
    left: 25%;
    transform: translate(-50%, -45%) !important;
  }

  .bottom-alert {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 3999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.error {
      background: #a94442;
    }

    &.success {
      background: rgba($nastyGreen, 0.95);
    }

    p {
      margin: 25px 15px;
      font-family: 'OpenSans-Regular';
      color: $white;
      text-align: center;
    }

    &-enter {
      height: 70px;
      transition: all 0.4s ease-in;

      &-done {
        height: 70px;
      }
    }

    &-exit {
      height: 0;
      transition: all 0.4s ease-out;

      &-done {
        max-height: 0;
      }
    }
  }
}

.select-state-modal.custom-modal--full {
  max-width: 100%;
  max-height: 100%;
}

.container {
  width: 1500px;
}
