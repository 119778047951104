@import 'components/ui/styles/colors.module';

.container {
  width: 100%;
}

.label {
  // Typography paragraph small bold
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 8px;
  display: inline-block;
}

.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  max-height: 48px;
  background-color: $white;
  border: 1px solid $gray-scale-50;
  border-radius: 12px;

  padding: 8px;
  width: 100%;

  &.error {
    border-color: $fail-700;
    background-color: $fail-100;
    margin-bottom: 8px;
  }

  &.copyable,
  &.cleanable {
    padding-right: 40px;
  }

  .leftIcon,
  .cleanIcon,
  .rightIcon {
    position: absolute;
    cursor: pointer;
  }

  .leftIcon {
    left: 10px;
  }

  .cleanIcon,
  .rightIcon {
    right: 10px;
  }
}

.leftIcon {
  display: flex;
}

.cleanIcon {
  display: flex;
}

.rightIcon {
  display: flex;
}

input {
  // Typography paragraph small
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px 12px;

  &.hasLeftIcon {
    padding-left: 32px;
  }

  &.hasRightIcon {
    padding-right: 32px;
  }

  &:focus {
    border-color: $gray-scale-100;
  }

  &::placeholder {
    color: $gray-scale-300;
  }

  &.error {
    color: $fail-700;
    border-color: $fail-700;
    background-color: $fail-100;

    &::placeholder {
      color: $fail-700;
    }
  }
}

.errors {
  &.error {
    color: $fail-700;
    font-family: Inter, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;

    &.dark {
      color: $fail-100;
    }
  }
}

.light {
  &.label {
    color: $black;
  }

  &.wrapper:focus-within:not(.readOnly) {
    border-color: $gray-scale-100;
  }
}

.dark {
  &.label {
    color: $white;
  }

  &.wrapper {
    background-color: $gray-scale-700;
    border-color: $gray-scale-400;

    &.error {
      border-color: $fail-700;
      margin-bottom: 8px;
      color: $fail-100;
      background-color: $fail-900;
    }
  }

  &::placeholder {
    color: $gray-scale-300;
  }

  input {
    background-color: $gray-scale-700;
    color: $white;
  }

  &.error {
    input {
      color: $fail-100;
      background-color: $fail-900;
    }

    ::placeholder {
      color: $fail-100;
    }
  }
}

.copyButton {
  cursor: pointer;
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  color: $links-300;
  font-weight: 600;
}
