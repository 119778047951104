@import 'components/ui/styles/colors-deprecated.module';

.mobile-redirector-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 50%)),
    url('/static/images/redirector-background.jpg');

  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    display: flex;
    flex-direction: row;
    color: $white;
    text-align: center;
    font-family: 'Fabriga Bold', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__container {
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: url('/static/images/dashboard/discover-box-mobile.jpg')
      no-repeat center;
    background-size: cover;
    border-radius: 16px;

    &__text {
      display: flex;
      flex-direction: row;
      color: $black;
      font-family: 'Fabriga Bold', serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      align-items: center;

      &__paragraph {
        margin-left: 16px;
      }
    }

    &__paragraph {
      color: $black;
      align-self: stretch;
      font-family: 'Inter', serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    &__buttons {
      display: flex;
      padding: 14.5px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 12px;
      background: $blue;
      margin: auto;
      width: 100%;

      .button-bar-web {
        color: white;
        text-align: center;
        font-family: 'Inter', serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: space-between;

        &__icon {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    &__link {
      a {
        color: $havelockBlue;
        text-align: center;
        font-family: 'Inter', serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 4px;
      }

      span {
        svg {
          margin-bottom: -3px;
        }
      }
    }
  }
}
