// Brand
$red: #f9213b;
$black: #1d1d1d;
$white: #ffffff;

// Gray Scale
$gray-scale-00: #fafafa;
$gray-scale-25: #f5f5f5;
$gray-scale-50: #e8e8e8;
$gray-scale-100: #b9b9b9;
$gray-scale-200: #979797;
$gray-scale-300: #686868;
$gray-scale-400: #4a4a4a;
$gray-scale-500: #282828;
$gray-scale-700: #1d1d1d;

// Marketing Secondary
$marketing-secondary-100: #f4f3f1;
$marketing-secondary-300: #ebe5e1;
$marketing-secondary-500: #dab2a2;

// Warning
$warning-100: #fde7ba;
$warning-300: #fbc64a;
$warning-500: #f9b021;

// Success
$success-100: #c9f2c8;
$success-300: #8be489;
$success-500: #52d64f;
$success-700: #3a9838;

// Fail
$fail-100: #fdbaba;
$fail-200: #ff7384;
$fail-300: #fb6a6a;
$fail-500: #f92121;
$fail-700: #b11717;
$fail-800: #a50d1f;
$fail-900: #3c1212;

// Links
$links-100: #6ba6ff;
$links-300: #0065ff;
$links-500: #003e9c;

:export {
  red: $red;
  black: $black;
  white: $white;
  grayScale00: $gray-scale-00;
  grayScale50: $gray-scale-50;
  grayScale100: $gray-scale-100;
  grayScale200: $gray-scale-200;
  grayScale300: $gray-scale-300;
  grayScale400: $gray-scale-400;
  grayScale500: $gray-scale-500;
  grayScale700: $gray-scale-700;
  marketingSecondary100: $marketing-secondary-100;
  marketingSecondary300: $marketing-secondary-300;
  marketingSecondary500: $marketing-secondary-500;
  warning100: $warning-100;
  warning300: $warning-300;
  warning500: $warning-500;
  success100: $success-100;
  success300: $success-300;
  success500: $success-500;
  success700: $success-700;
  fail100: $fail-100;
  fail200: $fail-200;
  fail300: $fail-300;
  fail500: $fail-500;
  fail700: $fail-700;
  fail800: $fail-800;
  fail900: $fail-900;
  links100: $links-100;
  links300: $links-300;
  links500: $links-500;
}
