@import 'components/ui/styles/tools-colors';

.sync-outdated-map-modal {
  width: 352px;

  main {
    padding: 16px 20px;

    font-family: 'Inter', serif;
    font-size: 14px;
  }

  footer {
    span {
      display: flex;
      align-items: center;
    }
  }
}
