$mobile-breakpoint: 449px; // mobile: from 0 to 448
$tablet-breakpoint: 769px; // ipad portrait: from 449 to 768
$phablet-breakpoint: 1025px; // ipad landscape: from 769 to 1024
$desktop-breakpoint: 1281px; // desktop: from 1025 to 1280

$reskin-desktop-breakpoint: 1316px;
$reskin-tablet-breakpoint: 992px;
$reskin-vh-breakpoint: 800px;

$desktop-content-width: 960px;

$breakpoints-list: (
  mobile: 449px,
  // mobile: from 0 to 448
  tablet: 769px,
  // ipad portrait: from 449 to 768
  phablet: 1025px,
  // ipad landscape: from 769 to 1024
  desktop: 1281px,
  // desktop: from 1025 to 1280,
);

@mixin phone {
  @media screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin phablet {
  @media screen and (max-width: $phablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

/*  mixin for media queries (based on map keys passed)  */
@mixin media-max($keys...) {
  @each $key in $keys {
    @media (max-width: map-get($breakpoints-list, $key)) {
      @content;
    }
  }
}

/*  mixin for media queries (based on map keys passed)  */
@mixin media-min($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpoints-list, $key)) {
      @content;
    }
  }
}

@mixin iphone-14-max {
  @media only screen and (max-width: 431px) and (max-height: 761px) {
    @content;
  }
}

@mixin ipad-v8 {
  @media only screen and (max-width: 811px) and (min-width: 739px) {
    @content;
  }
}

/*
  // How to use it in another component.
  // Import the breakpoints defined here
  @import 'src/utils/helpers/breakpoints';

  // Here define all the styles for the smallest screen. From there you only add styles inside of each screen breakpoint.


  // mobile: from 0 to 448
  @include phone() {
  }

  // ipad portrait: from 449 to 768
  @include tablet() {
  }

  // ipad landscape: from 769 to 1024
  @include phablet() {
  }

  // desktop: from 1025 to 1280
  @include desktop() {
  }


  // You can find a real example of how to use it in src/components/Modals/layouts/ParcelCsvExport/styles.module.scss
*/
