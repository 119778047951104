@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/opensans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('../../static/fonts/opensans/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/opensans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans-Light-Italic';
  src: url('../../static/fonts/opensans/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../../static/fonts/opensans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../../static/fonts/opensans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url('../../static/fonts/opensans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url('../../static/fonts/opensans/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('../../static/fonts/opensans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: url('../../static/fonts/opensans/OpenSans-SemiBoldItalic.ttf');
}
