@import 'components/ui/styles/tools-colors';

.save-outdated-map {
  min-width: 550px;
  max-width: 550px;
  overflow: hidden;

  > main {
    padding: 0;
  }

  &__notice {
    margin: 16px 20px;
    display: flex;
    justify-content: center;
    font-weight: 700;
  }

  &__message {
    font-family: 'Inter', serif;
    font-weight: 400;
    padding: 20px 30px;
    margin: 0;
  }

  &__actions {
    display: flex;
    justify-content: center;
    padding: 10px 0 24px;

    button {
      width: 160px;
      padding-top: 9px;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
