@import 'components/ui/styles/colors.module';

.background {
  background: $gray-scale-50;
  border-radius: 38px;
}

.fill {
  background: $gray-scale-100;
  border-radius: 38px;
  height: 100%;
}
