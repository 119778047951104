@import 'components/ui/styles/colors-deprecated.module';

.select-state-modal {
  max-width: 560px;

  &__header {
    text-align: center;
    margin-top: 20px;
    position: relative;

    p.title,
    p.subtitle {
      font-family: 'Fabriga Bold', 'Inter', serif;
      font-style: normal;
      font-weight: 700;
      color: $blackLight;
    }

    .title {
      font-size: 17px;
      line-height: 20px;
    }

    .subtitle {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 5px;
    }

    &__close {
      position: absolute;
      height: 30px;
      width: 30px;
      top: 0;
      right: 20px;
      border-radius: 50%;
      border: 1px solid $silver;
      outline: 0;

      span {
        color: $silver;
        font-size: 18px;
        margin-top: 4px;
      }
    }
  }

  &__selected_state {
    text-align: center;
    font-family: 'Fabriga Bold', 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $blue;
  }

  &__form {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 6px;
    justify-content: center;
    margin-bottom: 20px;

    label {
      font-weight: normal;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    &__checkbox {
      border: 2px solid $blueGreyish;
      border-radius: 2px;
    }

    p {
      margin: 0;
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $independence;
    }
  }

  &__map,
  &__map_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  &__map {
    margin: 0 -20px;
    position: relative;
    background: $grayLightest;

    p {
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $grayMedium;
      margin-bottom: 30px;
    }
  }

  &__map_input {
    svg {
      margin: 30px 0 30px 0;
      display: block;
    }
  }

  &__footer {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 450px;
    }

    &__outside-button {
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-transform: none;
      color: $blue;
      margin-top: 20px;
    }

    &__outside-button:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .reskin-checkbox {
    border: 1.5px solid $grayLight;

    &--checked {
      border: 1.5px solid $blue;
    }
  }
  svg text {
    pointer-events: none;
    user-select: none;
    display: block;
  }
}
