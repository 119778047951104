// NOTE: this file has some colors/fonts that are not defined in the design system
// because we want to make this component equal to the static html+css version in
// `embed.html`. which loads much before than the react app.

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/static/images/embed-placeholder.jpg') 50% / cover no-repeat;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
}

.logo {
  width: 133px;
  height: 58px;
}

.title {
  color: #979797;
  font-family: Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.progressBarContainer {
  margin-top: 5px;
}
