@import 'components/ui/styles/colors-deprecated.module';

.reskin-modal-overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(black, 0.5);
  z-index: 6001;
}

.reskin-modal {
  display: flex;
  flex-direction: column;

  width: 352px;
  border-radius: 12px;

  position: absolute;
  margin: 100px 0;
  left: 50%;
  transform: translate(-50%, 0) !important;

  background: white;
  overflow: auto;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

  &__close-button,
  &__back-button {
    border: none;
    background: none;
    padding: 0 !important;
  }

  &__back-button {
    height: 12px !important;
    width: 12px !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    color: $blackLight;
    margin: 0 20px;
    padding: 16px 0;
    border-bottom: 1px solid $wildSand;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__title {
    display: flex;
    gap: 10px;
  }

  &__alert {
    .bottom-alert {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.error {
        background: $mandy;
      }

      &.success {
        background: rgba($starbur, 0.95);
      }

      p {
        margin: 25px 15px;
        font-family: 'Fabriga', sans-serif;
        color: #ffffff;
        text-align: center;
      }
    }
  }

  footer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    column-gap: 8px;
    padding: 16px 20px;

    width: 100%;

    button {
      border-radius: 6px;
      height: 32px;
      padding: 8px 16px;
      margin: 0;
      width: auto;

      font-size: 13px;
      line-height: 16px;
    }
  }

  &--danger {
    .reskin-modal__header {
      color: $red;
    }
  }

  &--wide {
    width: 600px;

    footer {
      padding-top: 12px;
      padding-bottom: 24px;

      button {
        height: 48px;
        border-radius: 12px;

        font-size: 16px;
      }
    }
  }
}
