// Blacks and Whites
$black: #000000;
$blackLight: #1d1d1d; // TODO replace with $gray-scale-700 from color palette
$blackLighter: #282828; // TODO replace with $gray-scale-500 from color palette
$mineShaft: #333333;
$outerSpace: #383d42;
$mako: #40474d;
$abbey: #464a4e;
$charcoal: #4a4a4a;
$doveGray: #666666; // TODO replace with $gray-scale-300 from color palette
$gray: #908d8d; // TODO replace with $gray-scale-200 from color palette
$osloGray: #85878a;
$silverChalice: #aeaeae;
$grayMedium: #979797; // TODO replace with $gray-scale-200 from color palette
$bombay: #b2b5b7;
$silver: #cccccc; // TODO replace with $gray-scale-100 from color palette
$grayLight: #b9b9b9; // TODO replace with $gray-scale-100 from color palette
$dustyGray: #999999;
$grayLightest: #e8e8e8; // TODO replace with $gray-scale-50 from color palette
$mercury: #e1e1e1;
$lightSilver: #d9d9d9;
$alto: #d8d8d8;
$seashell: #f1f1f1;
$wildSand: #f5f5f5; // TODO replace with $gray-scale-25 from color palette
$whiteHighlight: #f0f6ff;
$light-gray: #f8fafb;
$antiFlashWhite: #edf2f7;
$white: #ffffff;

// Reds
$redDark: #b11717; // TODO replace with $fail-700 from color palette
$redDelete: #f92121; // TODO replace with $fail-500 from color palette
$red: #f9213b;
$cinnabar: #e53e3e;
$redOrange: #fa3030;
$mandy: #dd4759;
$redLight: #fdbaba; // TODO replace with $fail-300 from color palette

// Blues
$elephant: #102c45;
$blueDark: #003e9c;
$independence: #4a5568; // TODO we don't have a similar color in the color palette. We need to sync with design team
$translucentLightSkyBlue: #6ba6ff1a;
$regalBlue: #20384e;
$cloudBurst: #243f5c;
$sanJuan: #294c6a;
$denim: #406788;
$greyBlue: #7091ad;
$blue: #0065ff; // TODO replace with $links-300 from color palette
$havelockBlue: #4a90e2;
$richElectricBlue: #0097db;
$scooter: #3bb2d0;
$lightBlueGray: #b1cae0;
$blueGreyish: #cadef6;
$baseColor: #ced4da;

// Greens
$green: #3a9838;
$starbur: #6dbe38;
$apple: #7bb242;
$nastyGreen: #7bb342;
$lightGreen: #c9f2c8;

// Oranges
$orange: #ff681f;
$lightOrange: #ffa500ff;

// Transparent
$whiteTransparent: #ffffff99; // TODO replace with rgba($white, 0.6); from color palette

:export {
  black: $black;
  blackLight: $blackLight;
  blackLighter: $blackLighter;
  dustyGray: $dustyGray;
  gray: $silver;
  grayMedium: $grayMedium;
  grayLight: $grayLight;
  grayLightest: $grayLightest;
  white: $white;
  seashell: $seashell;
  doveGray: $doveGray;
  charcoal: $charcoal;
  redDark: $redDark;
  red: $red;
  redDelete: $redDelete;
  redLight: $redLight;
  blue: $blue;
  blueLighter: $havelockBlue;
  green: $green;
}
