@import 'components/ui/styles/colors-deprecated.module';
@import 'components/ui/styles/breakpoints-deprecated';

.choose-account-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $blackLight;
  top: 20%;
  width: 338px;
  overflow-y: auto;
  max-height: calc(100% - 100px);

  .title {
    width: 100%;
    font-family: 'Fabriga Bold', serif;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: $white;
    border-bottom: 1px solid $blackLighter;
    padding: 16px 20px;
  }

  .account-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    .account-item {
      padding: 12px 20px;
      display: flex;
      align-items: center;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $blackLighter;
      &:hover {
        background: $blackLighter;
        cursor: pointer;
      }
      &--selected {
        background: $blackLighter;
      }

      span {
        height: 50px;
        width: 50px;

        svg {
          height: 50px;
          width: 50px;
        }
      }

      &__name {
        width: 200px;
        font-family: 'Fabriga Bold', serif;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-left: 14px;
        margin-right: 22px;
        color: $white;
      }

      .circle {
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid $independence;

        &--selected {
          outline: 2px solid $blue;
          background: $blue;
          border: 3px solid $blackLight;
        }
      }

      .chevron {
        display: flex;
        height: 14px;
        width: 14px;

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
    .account-details {
      padding: 12px 30px;
      display: flex;
      align-items: center;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $blackLighter;
      background: $blackLighter;
      &:hover {
        background: $blackLighter;
        cursor: pointer;
      }
      span {
        height: 36px;
        width: 36px;

        svg {
          height: 30px;
          width: 30px;
        }
      }

      &__name {
        width: 200px;
        font-family: 'Fabriga Bold', serif;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-left: 14px;
        margin-right: 22px;
        color: $white;
      }

      .circle {
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid $independence;

        &--selected {
          outline: 2px solid $blue;
          background: $blue;
          border: 3px solid $blackLight;
        }
      }

      .chevron {
        display: flex;
        height: 14px;
        width: 14px;

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  &.top-nav-version {
    position: fixed;
    top: 0;
    left: calc(100% - 180px);
    margin-top: 85px;
  }

  .sign-out {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: transparent;
    border: none;
    height: 40px;
    padding: 20px;
    font-family: 'Inter', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: $havelockBlue;
  }
  .release-notes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    color: $grayMedium;
    cursor: pointer;
    font-family: 'Inter', serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 338px;
    height: 15px;
    justify-content: center;
  }
  .version {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    color: $grayMedium;
    font-family: 'Inter', serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 20px;
    width: 338px;
    height: 60px;
    justify-content: center;
    border-top: 1px solid $blackLighter;
  }

  .sign-out-list {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 390px) {
  .choose-account-modal {
    width: 350px;
    position: absolute;
    &.top-nav-version {
      top: 5px;
      left: 50%;
      margin-top: 295px;
    }
  }
}
