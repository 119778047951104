@import 'components/ui/styles/colors-deprecated.module';

.reskin-link-container {
  padding: 14.5px 20px;
  width: 280px;
  border: none;
}

.reskin-link {
  color: $blue;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  &--blue {
    color: $blue;
    &:hover {
      color: $blue;
    }
  }

  &--gray {
    color: $grayMedium;
    &:hover {
      color: $grayMedium;
    }
  }
}

.sign-in a.reskin-link-inline {
  display: inline-block;
}
